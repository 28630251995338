import {IBooleanSelection, IPromoCategory, IYesNo, PromoAreaTreeItem} from '../interfaces/promotion-discounts';

// export const PROMO_STEP_PROMO_CODE = 1 + PROMO_STEP_DETAIL;

export const PROMO_STEP_DETAIL          = 0;
export const PROMO_STEP_FILTER          = 1 + PROMO_STEP_DETAIL;
export const PROMO_STEP_FILTER_CUSTOMER = 1 + PROMO_STEP_FILTER;
export const PROMO_STEP_SUMMARY         = 1 + PROMO_STEP_FILTER_CUSTOMER;

export const PAGE_SIZE_SMALL = 5;
export const PAGE_SIZE_MEDIUM = 10;
export const PAGE_SIZE_LARGE = 20;
export const PAGE_SIZE_XLARGE = 50;
export const PAGE_SIZE_XXLARGE = 100;

export const PAGE_SIZES = [
	PAGE_SIZE_SMALL,
	PAGE_SIZE_MEDIUM,
	PAGE_SIZE_LARGE,
	PAGE_SIZE_XLARGE,
	PAGE_SIZE_XXLARGE];

export enum FormMode {
  New = 1,
  Edit = 2,
  View = 3,
}

export function FormModeAsText(mode: FormMode): string {
  switch (mode) {
    case FormMode.New:    return 'New';
    case FormMode.Edit:   return 'Edit';
    case FormMode.View:   return 'View';
    default:              return '';
  }
}

export const RANDOM_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ12345679';

export function generateRandomString(length: number): string {
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * RANDOM_CHARACTERS.length);
    result += RANDOM_CHARACTERS.charAt(randomIndex);
  } return result;
}

export function booleanList(): IYesNo[] {
  return [
    { id: false, name: 'No' },
    { id: true, name: 'Yes' },
  ];
}

export function inArrearsList(): IBooleanSelection[] {
  return [
    { id: null, name: 'Not Applicable' },
    { id: true, name: 'In Arrears' },
    { id: false, name: 'Up To Date' },
  ]
}

export enum TPromoFilterDataType {
  ActivationDate = 1,     // Activation Date
  ApplicationDate = 2,    // Application Date
  CancellationDate = 3,   // Cancellation Date
  PeriodEndDate = 4,      // Period End Date
  SuspensionDate = 5,     // Suspension Date
  PromoDiscountDate = 6,  // Promo Discount Date
  PromoDiscountEndDate = 7,  // Promo Discount End Date
  PromoDiscountPublishedDate = 8,  // Promo Discount End Date
}

export enum TDateRangeOperator {
  Before = 1,         // Before the selected date
  BeforeOrOn = 2,     // Before or on the selected date
  After = 3,          // After the selected date
  AfterOrOn = 4,      // After or on the selected date
  Between = 5,        // Between the selected dates
  NotBetween = 6,     // Not between the selected dates
  On = 7,             // On the selected date
}

export function filterDataTypeAsText(filterDataType: TPromoFilterDataType): string {
  switch (filterDataType) {
    case TPromoFilterDataType.ActivationDate:       return 'Activation Date';
    case TPromoFilterDataType.ApplicationDate:      return 'Application Date';
    case TPromoFilterDataType.CancellationDate:     return 'Cancellation Date';
    case TPromoFilterDataType.PeriodEndDate:        return 'Period End Date';
    case TPromoFilterDataType.SuspensionDate:       return 'Suspension Date';
    case TPromoFilterDataType.PromoDiscountDate:    return 'Promo Start Date';
    case TPromoFilterDataType.PromoDiscountEndDate: return  'Promo End Date';
    case TPromoFilterDataType.PromoDiscountPublishedDate: return  'Promo Published Date';
    default:                                        return '';
  }
}

export function dateRangeOperatorAsText(dateRangeOperator: TDateRangeOperator): string {
  switch (dateRangeOperator) {
    case TDateRangeOperator.Before:     return 'Before';
    case TDateRangeOperator.BeforeOrOn: return 'Before or on';
    case TDateRangeOperator.After:      return 'After';
    case TDateRangeOperator.AfterOrOn:  return 'After or on';
    case TDateRangeOperator.Between:    return 'Between';
    case TDateRangeOperator.NotBetween: return 'Not Between';
    case TDateRangeOperator.On:         return 'On';
    default:                            return '';
  }
}

export enum TPromoCategoryQueryTypeId {
  SingleService = 1,
  MultipleService = 2,
  EligibilityFilter = 3,
  Any = 4,
}

export enum TPromoStatus {
  Active = 1,
  FutureDated = 2,
  Draft = 3,
  Deleted = 4,
  Expired = 5,
}

export function promoStatusAsText(promoStatus: TPromoStatus): string {
  switch (promoStatus) {
    case TPromoStatus.Active:       return 'Active';
    case TPromoStatus.FutureDated:  return 'Future Dated';
    case TPromoStatus.Draft:        return 'Draft';
    case TPromoStatus.Deleted:      return 'Deleted';
    case TPromoStatus.Expired:      return 'Expired';
    default:                        return '';
  }
}

export function getPromoFormTitleClass(promoStatus: TPromoStatus): string {
  switch (promoStatus) {
    case TPromoStatus.Draft:				return 'status-draft';
    case TPromoStatus.Active:				return 'status-active';
    case TPromoStatus.FutureDated:	return 'status-future-dated';
    case TPromoStatus.Expired:
    case TPromoStatus.Deleted:  	  return 'status-deleted';
    default:												return '';
  }
}

export enum TBillingSchemeProductType {
  Recurring = 1,
  DayBundle = 2,
  DayBundleWithAutoRecharge = 3
}

export function getCategoryProcessDescription(
  priceReductionPriceCode: string,
  promoCategoryItem: IPromoCategory,
): string {
  let description: string;
  switch (promoCategoryItem.query_type_id) {
    case TPromoCategoryQueryTypeId.SingleService:
      description = `${priceReductionPriceCode} is applicable to a SINGLE customer.`;
      break;
    case TPromoCategoryQueryTypeId.MultipleService:
      description = `${priceReductionPriceCode} is applicable to MULTIPLE customers.`;
      break;
    case TPromoCategoryQueryTypeId.EligibilityFilter:
      description = `${priceReductionPriceCode} is defined by the ELIGIBILITY FILTER criteria.`;
      break;
    default:
      description = `Unhandled category query type: ${promoCategoryItem.query_type_id}`;
  }

  if (promoCategoryItem.benefit_count_applicable)
    description += ` Period Length is required.`;
  else
    description += ` Period Length cannot be adjusted.`;

  return description;
}

// Check if two arrays are identical
export function areArraysIdentical<T extends string | number>(sourceArray: T[], targetArray: T[]): boolean {
  return sourceArray.length === targetArray.length &&
    sourceArray.every((value, index) => value === targetArray[index]);
}

// Remove duplicates from an array
export function unionArrays(array1: string[], array2: string[]): string[] {
  return Array.from(new Set([...array1, ...array2]));
}

// The type parameter T must have a 'name' property of type string and an 'id' property of type string or number
export function filterSublist<T extends { name: string; id: string | number }>(
  sourceList: T[],
  searchTerm: string,
): T[] {
  const contains = (value: string) => {
    return (item: T) => item.name.toLowerCase().includes(value.toLowerCase());
  };
  return sourceList.filter(contains(searchTerm));
}

export function findPromoAreaTreeItemById(areaTreeArray: PromoAreaTreeItem[], id: string): PromoAreaTreeItem | null {
  // Search in the current level
  for (const areaTreeItem of areaTreeArray) {
    // Found item?
    if (areaTreeItem.id === id)
      return areaTreeItem;
    // Not found, search in subtree
    if (areaTreeItem.children.length > 0) {
      // Recursive search
      const found = findPromoAreaTreeItemById(areaTreeItem.children, id);
      if (found)
        return found;
    }
    // Not found, continue searching
  }
  return null;
}

export function formatDateYYYYMMDD(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function getLocalDate(dateString: string): Date{
	return new Date(`${dateString}T00:00:00`);
}
